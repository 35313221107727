// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { UserSquare, HomeTrendUp, Story, PresentionChart, Driving, Bag2, AddSquare, MoneyChange, Check } from 'iconsax-react';

// icons
const icons = {
  dashboard: HomeTrendUp,
  SubAdmin: UserSquare,
  mobileVan: Driving,
  widgets: Story,
  statistics: Story,
  request: AddSquare,
  return: MoneyChange,
  checklist: Check,
  // data: Fatrows,
  order: Bag2,
  chart: PresentionChart
};

// ==============================|| MENU ITEMS - WIDGETS ||============================== //

const widget = {
  id: 'group-widget',
  title: <FormattedMessage id="widgets" />,
  icon: icons.widgets,
  type: 'group',
  children: [
    {
      id: 'dashboard',
      title: <FormattedMessage id="dashboard" />,
      type: 'item',
      url: '/dashboard',
      icon: icons.dashboard
    },
    {
      id: 'sub-admin',
      title: <FormattedMessage id="sub-admin" />,
      type: 'collapse',
      icon: icons.SubAdmin,
      children: [{
        id: 'list',
        title: <FormattedMessage id="list" />,
        type: 'item',
        url: '/sub-admin'
      },{
        id: 'add-subadmin',
        title: <FormattedMessage id="add-subadmin" />,
        type: 'item',
        url: '/sub-admin/add-subadmin'
      }]
    },
    // {
    //   id: 'vendor',
    //   title: <FormattedMessage id="vendor" />,
    //   type: 'item',
    //   url: '/vendor',
    //   icon: icons.SubAdmin
    // },
    {
      id: 'partner',
      title: <FormattedMessage id="partner" />,
      type: 'collapse',
      icon: icons.SubAdmin,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/vendor'
        },
        {
          id: 'add-partner',
          title: <FormattedMessage id="add-partner" />,
          type: 'item',
          url: '/vendor/add-vendor'
        }

      ]
    },
    
    {
      id: 'in-store',
      title: <FormattedMessage id="in-store" />,
      type: 'collapse',
      // url: '/franchise',
      icon: icons.SubAdmin,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/franchise'
        },
        {
          id: 'add-franchise',
          title: <FormattedMessage id="add-franchise" />,
          type: 'item',
          url: '/franchise/add-franchise'
        }
      ]
    },
    {
      id: 'request',
      title: <FormattedMessage id="request" />,
      type: 'collapse',
      icon: icons.request,
      breadcrumbs: true,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/request'
        }
      ]
    },
    {
      id: 'wheelz',
      title: <FormattedMessage id="wheelz" />,
      type: 'collapse',
      // url: '/mobile-van',
      icon: icons.mobileVan,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/wheelz'
        },
        {
          id: 'add-wheelz',
          title: <FormattedMessage id="add-wheelz" />,
          type: 'item',
          url: '/wheelz/add-wheelz'
        }
      ]
    },
    {
      id: 'return',
      title: <FormattedMessage id="return" />,
      type: 'collapse',
      // url: '/mobile-van',
      icon: icons.return,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/return'
        },
        {
          id: 'add-reason',
          title: <FormattedMessage id="add-reason" />,
          type: 'item',
          url: '/return/add-reason'
        }
      ]
    },
    {
      id: 'checklist',
      title: <FormattedMessage id="checklist" />,
      type: 'collapse',
      // url: '/mobile-van',
      icon: icons.checklist,
      children: [
        {
          id: 'list',
          title: <FormattedMessage id="list" />,
          type: 'item',
          url: '/checklist'
        },
        {
          id: 'add-checklist',
          title: <FormattedMessage id="add-checklist" />,
          type: 'item',
          url: '/checklist/add-checklist'
        }
      ]
    },
    {
      id: 'order',
      title: <FormattedMessage id="order" />,
      type: 'item',
      url: '/order',
      icon: icons.order
    }
    // {
    //   id: 'statistics',
    //   title: <FormattedMessage id="statistics" />,
    //   type: 'item',
    //   url: '/widget/statistics',
    //   icon: icons.statistics
    // },
    // {
    //   id: 'data',
    //   title: <FormattedMessage id="data" />,
    //   type: 'item',
    //   url: '/widget/data',
    //   icon: icons.data
    // }
    // {
    //   id: 'chart',
    //   title: <FormattedMessage id="chart" />,
    //   type: 'item',
    //   url: '/widget/chart',
    //   icon: icons.chart
    // }
  ]
};

export default widget;
